<template>
  <!--
    BILL TYPE (Cash, Credit)
    ==========

    Due Date is only on the Credit Bill Type.

    Ledger Head Addition Info(Closing Balance/VatPan No.)

    Narration Purchase




    -->

  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <v-card-title class="title">
              <v-icon>layers</v-icon>
              Purchase
              <v-spacer></v-spacer>
              <add-button
                permission="subject-read"
                @action="
                  (form.dialog = true), fetchLedgers(), clearInventoryItems()
                "
              >
                New Purchase
              </add-button>
            </v-card-title>
            <v-card flat>
              <v-card-title class="title"> </v-card-title>
            </v-card>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template slot="items" slot-scope="props">
              <td>{{ ++props.index }}</td>
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.parent_name }}</td>
              <td class="text-xs-left">
                <v-chip
                  v-if="props.item.is_primary === 1"
                  color="red"
                  text-color="white"
                  >Yes</v-chip
                >
                <v-chip v-else color="green" text-color="white">No</v-chip>
              </td>

              <td class="text-xs-right">
                <span v-if="props.item.company_id">
                  <edit-button
                    permission="subject-read"
                    @agree="form.edit(props.item), editData(props.item)"
                  />
                  <delete-button
                    permission="section-create"
                    @agree="form.delete(props.item.id)"
                  />
                </span>
              </td>
            </template>
            <template slot="no-data">
              No purchase list for the given search.
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!--TODO
            bill_no
            purchase_bill_date
            Unit Declare Item Select gardaa hunxa ki item add garda
            Item openings haru fiscal year sanga related hunxa?
        -->

    <v-dialog v-model="form.dialog" persistent max-width="900px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">New Purchase</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <v-form
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md class="pa-0">
              <v-layout row wrap>
                <v-flex xs12>
                  <h2>
                    <a
                      style="color: #555; text-decoration: none"
                      href=""
                      @click.prevent="addInventoryItems()"
                      >Items ({{ inventoryItems.length }})
                      <v-icon>add_circle</v-icon>
                    </a>

                    <span v-if="totalAmount" style="color: #666"
                      >&nbsp;&nbsp;<small>{{
                        currencyFormat(totalAmount)
                      }}</small></span
                    >
                  </h2>
                </v-flex>

                <div></div>

                <v-container grid-list-md class="pa-0">
                  <v-layout
                    v-for="(item, key) in inventoryItems"
                    :key="key"
                    row
                    wrap
                  >
                    <v-flex xs3>
                      <v-text-field
                        v-model="item.id"
                        name="bill_date"
                        label="Item Name"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>

                    <v-flex xs1>
                      <v-text-field
                        v-mask="'####'"
                        v-model="item.quantity"
                        name="bill_date"
                        label="Qt"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>

                    <v-flex xs2>
                      <v-text-field
                        v-mask="'###'"
                        v-model="item.unitPrice"
                        name="bill_date"
                        label="Item Rate (Unit)"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>

                    <v-flex xs4>
                      <v-text-field
                        v-model="item.ware_house_id"
                        name="bill_date"
                        label="Ware House"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-flex xs2>
                      <p
                        style="display: inline-block"
                        v-if="item.unitPrice && item.quantity"
                      >
                        <small style="color: #999">Amount</small>
                        <br />
                        <span>{{
                          currencyFormat(
                            parseFloat(item.unitPrice) *
                              parseFloat(item.quantity)
                          )
                        }}</span>
                      </p>
                      <a
                        v-if="key !== 0"
                        style="
                          text-decoration: none;
                          float: right;
                          padding-right: 20px;
                        "
                        href=""
                        @click.prevent="inventoryItems.splice(key, 1)"
                      >
                        <v-icon color="red">close</v-icon>
                      </a>
                    </v-flex>
                  </v-layout>
                  <br />
                </v-container>

                <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex>

                <br />
                <br />
                <v-flex xs3>
                  <v-text-field
                    v-mask="'####-##-##'"
                    v-model="form.bill_date"
                    name="bill_date"
                    label="Bill Date"
                    :error-messages="form.errors.get('bill_date')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>

                <v-flex xs3>
                  <v-text-field
                    v-mask="'####-##-##'"
                    v-model="form.purchase_bill_date"
                    name="bill_date"
                    label="Purchase Bill Date"
                    :error-messages="form.errors.get('purchase_bill_date')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>

                <v-flex xs3>
                  <v-text-field
                    v-mask="'####-##-##'"
                    v-model="form.due_date"
                    name="due_date"
                    label="Due Date"
                    :error-messages="form.errors.get('due_date')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>

                <v-flex xs3>
                  <v-text-field
                    v-model="form.purchase_bill_no"
                    :height="25"
                    name="due_date"
                    label="Purchase Bill No."
                    :error-messages="form.errors.get('purchase_bill_no')"
                    autocomplete="off"
                  />
                </v-flex>

                <v-flex xs3>
                  <v-select
                    :items="billTypes"
                    required
                    label="Bill Type*"
                    v-model="form.bill_type"
                    :error-messages="form.errors.get('bill_type')"
                    name="bill_type"
                  />
                </v-flex>

                <v-flex xs5>
                  <v-select
                    :items="ledgerHeads"
                    required
                    label="Ledger Head*"
                    v-model="form.ledger_head_id"
                    :error-messages="form.errors.get('ledger_head_id')"
                    name="ledger_head_id"
                  />
                </v-flex>

                <v-flex class="pt-0" xs4>
                  <v-checkbox
                    class="pt-0"
                    color="blue"
                    v-model="form.is_vat_calculated"
                  >
                    <template v-slot:label>
                      <div style="color: #333">
                        VAT
                        <strong>{{
                          form.is_vat_calculated === true
                            ? "will be"
                            : "won't be"
                        }}</strong>
                        calculated.
                        <p style="line-height: 10px">
                          <small
                            v-if="form.is_vat_calculated === true"
                            style="color: #666; font-size: 10px"
                          >
                            Uncheck to cancel the VAT calculation
                          </small>
                          <small v-else style="color: #666; font-size: 10px">
                            Check for VAT calculation
                          </small>
                        </p>
                      </div>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            outlined
            text
            @click="(form.dialog = false), form.reset()"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined text @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { currencyFormat } from "../../../../library/helpers";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        bill_no: "",
        bill_date: "",
        purchase_bill_no: "",
        purchase_bill_date: "",
        bill_type: "",
        ledger_head_id: "",
        due_date: "",
        is_vat_calculated: true,
        user_id: "",
      },
      "api/account/inventory/purchase"
    ),

    inventoryItems: [
      {
        id: "",
        quantity: "",
        unitPrice: "",
        ware_house_id: "",
      },
    ],

    isLoading: false,
    addNewItemModal: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
      sortBy: "id",
    },
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      {
        text: "Name",
        value: "name",
        align: "left",
        width: "30%",
        sortable: false,
      },
      {
        text: "Parent",
        value: "parent",
        align: "left",
        width: "20%",
        sortable: false,
      },
      {
        text: "Primary",
        value: "is_primary",
        align: "left",
        width: "20%",
        sortable: false,
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
    billTypes: ["Cash", "Cheque"],
    ledgerHeads: [],
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
    totalAmount() {
      let totalAmount = 0;
      this.inventoryItems.map(function (item) {
        if (item.quantity && item.unitPrice)
          totalAmount += parseFloat(item.quantity) * parseFloat(item.unitPrice);
      });
      return totalAmount;
    },
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;

      this.isLoading = true;
      this.$rest
        .get("/inventory/api/godown?parent=1&search=" + val)
        .then(({ data }) => {
          // this.entries = data.data
          this.entries = data.data.map((res) => {
            return { name: res.name, id: res.id };
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },

  methods: {
    currencyFormat,
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&")
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {});
    },
    fetchLedgers() {
      this.$rest
        .get("/api/ledger-head?type=creditors&slim=true")
        .then(({ data }) => {
          this.ledgerHeads = data.data.map(function (item) {
            return {
              text: item.name,
              value: item.id,
            };
          });
        });
    },
    editData(data) {
      this.entries = [];
      this.entries.push({ name: data.parent_name, id: data.parent_id });
    },

    save() {
      this.form.is_vat_calculated = this.form.is_vat_calculated ? 1 : 0;
      this.form.store();
    },
    clearInventoryItems() {
      this.inventoryItems = [
        {
          id: "",
          quantity: "",
          unitPrice: "",
          ware_house_id: "",
        },
      ];
    },
    addInventoryItems() {
      let add = true;
      this.inventoryItems.map(function (item) {
        if (!item.id || !item.unitPrice || !item.quantity) {
          add = false;
          console.log(item);
        }
      });
      if (add)
        this.inventoryItems.push({
          id: "",
          quantity: "",
          unitPrice: "",
          ware_house_id: "",
        });
    },
  },
};
</script>
<style lang="scss">
</style>

